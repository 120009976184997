#sidebar {
    height: 100%;
    border-color: var(--dark-teal);
}

#sidebar > div {
    background-color: var(--teal);
    color: white;
}

#sidebarHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
}

#sidebarHeaderCollapsed {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 2rem;
}

#sidebarHeader > img, #sidebarHeaderCollapsed > img {
    width: 5rem;
}

#sidebarBtn {
    border: none;
}

#sidebarBtn:hover, #sidebarBtn:focus {
    background-color: transparent;
    color: var(--darker-teal);
}

#sidebarBtnCollapsed {
    display: flex;
    align-items: center;
    border: 1px solid white;
    padding: 10px 12px;
}

.menuItem:hover, .menuItem > a:hover {
    background-color: var(--dark-teal) !important;
    color: var(--light-teal);
}

.selectedMenuItem {
    background-color: var(--dark-teal) !important;
}

#createPostBtn {
    background-color: var(--lightish-teal) !important;
    color: var(--grey);
    margin: 0.5rem 0;
}

#createPostBtn:hover, #createPostBtn > a:hover {
    background-color: var(--darker-teal) !important;
    color: var(--yellow);
}
