.formContainer {
    position: sticky;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1rem;
    background-color: var(--dark-grey);
}

.commentInputContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.commentInput {
    height: 100% !important;
}

.commentFormToolbar {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0 1rem;
}