html {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* Globals */
:root {
  --dark-grey: #1E1E1E;
  --grey: #2C3333;
  --lightish-grey: #454D4B;
  --light-grey: #B6C6C6;
  --dark-teal: #0B6B6F;
  --darker-teal: #2E4F4F;
  --teal: #0E8388;
  --lightish-teal: #a1b4af;
  --light-teal: #CBE4DE;
  --yellow: #F6DD39;
}
