@keyframes fade-in {
  from {
    opacity: 0;
  }
  to { 
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  50% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% { 
    opacity: 1;
  }
}

#container {
  display: flex;
  flex-direction: column;
}

#header {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, var(--teal) 0%, rgba(11,107,111,0.1) 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 80%, 0 100%);
  padding: 0 10px 50px 10px;
  min-width: fit-content;
  cursor: default;
  overflow: hidden;
  animation: fade-in 1s forwards;
}

#header #avatarContainer {
  padding: 3px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 0;
}

#header #avatarContainer > img {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  border: 1.5px solid var(--dark-teal);
  box-shadow: 0px 2px 0px var(--darker-teal);
}

#header #identityContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
}

#header #identityContainer > #username {
  font-size: 3em;
  color: white;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
}

#header #identityContainer > #bio {
  color: gainsboro;
  font-size: 1em;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
}

#subdomain {
  opacity: 0.5;
  font-size: 0.5em;
}

#stats {
  display: flex;
  gap: 20px;
  padding: 10px;
  color: var(--light-teal);
}

#stats .item {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

#stats .item .itemAmount {
  font-size: 1.5em;
}

#profileButtons {
  display: flex;
  flex-grow: 0;
  padding: 10px;
  margin-left: 10px;
  gap: 20px;
  height: fit-content;
}

#github {
  display: flex;
  height: 40px;
  justify-content: flex-end;
  padding: 7px;
}

#github img {
  height: 100%;
}

#feed {
  width: 100%;
  margin-top: 20px;
  animation: fade-in-top 1.5s forwards;
}
