.selectToolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
}

.submitToolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.submitButton {
    background-color: var(--dark-teal);
    border: 1px solid var(--dark-teal);
    border-radius: 0.25rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.submitButton:hover {
    background-color: var(--teal);
    border-color: var(--teal);
}

.deleteButton {
    border-radius: 0.25rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
}