.postContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    gap: 10px;
    border-radius: 10px;
    background-color: var(--dark-grey);
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.5);
    margin: 0 5px;
    color: white;
}

.ad {
    box-shadow: 0px 4px 6px rgba(255, 255, 255, 0.5) !important;
}

.ad .postAuthor {
    color: yellow !important;
}

.postHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.postProfilePicture {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    overflow: hidden;
    object-fit: cover;
    background-color: var(--lightish-grey);
}

.postInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.postAuthor {
    display: flex;
    border-radius: 10px;
    background-color: var(--lightish-grey);
    padding: 10px;
    font-size: 24px;
}

.postAuthor a {
    text-decoration: none;
    color: white;
}

.postAuthor a:hover, .postAuthor a:focus {
    color: var(--teal);
}

.postSubInfo {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.postSubInfoItem {
    border-radius: 10px;
    background-color: var(--lightish-grey);
    padding-left: 10px;
    padding-right: 10px;
    overflow-wrap: break-word;
}

.postBody {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: var(--lightish-grey);
    padding: 15px;
    width: 100%;
}

.postButtons {
    display: flex;
    justify-content: space-around;
}

.postButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
}

.postButton {
    width: 30px;
    height: 30px;
    margin: 10px;
    color: white;
}

.postButton:hover {
    cursor: pointer;
}

.postShare:hover {
    color: limegreen;
}

.postLink:hover {
    color: deepskyblue;
}

.postLike:hover {
    color: red;
}

.postLiked {
    color: red;
    cursor: default !important;
}

.likeCount {
    color: white;
    font-size: 20px;
    cursor: default;
}

.postEdit:hover {
    color: orchid;
}

.postComment:hover {
    color: goldenrod;   
}

.postDelete:hover {
    color: coral;
}

.postTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    overflow-wrap: break-word;
}

.postDescr {
    color: grey;
    font-style: italic;
    text-align: center;
    overflow-wrap: break-word;
}

.postContent {
    font-size: 20px;
    overflow-wrap: break-word;
}

.postContent img {
    max-width: 100%;
    height: auto;
}
