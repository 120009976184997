#container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5rem;
}

#content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#header {
  display: flex;
  margin-bottom: 25px;
  padding: 1rem;
  position:relative;
  text-align: center;
}

#header .headerItem {
  color: var(--light-teal);
  font-size: 2.5em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  cursor: default;
  font-weight:bold;
}

#header .headerItem .headerItemAmount {
  font-size: 1.25em;
}
