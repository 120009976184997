/* Container to ensure footer is always at the bottom of the page */
#root {
    width: 100%;
    height: 100%;
    background-color: var(--grey);
    display: flex;
    flex-direction: column;
}

#contentRoot {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 64px);
}

#contentAndUpperNav {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.content {
    flex-grow: 1;
    height: 100%;
}

#thinNavbar {
    display: flex;
    justify-content: flex-end;
    background-color: var(--dark-grey);
    border-bottom: 1px solid var(--grey);
    padding: 10px;
}

#thinNavbar button {
    /* margin-left: 20px; */
    margin-right: 20px;
    padding: 4px 10px;
    background-color: var(--yellow);
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

#thinNavbar button:hover {
    background-color: #0056b3; 
}

.overflowScroll {
    overflow-y: auto;
}

.inboxButtonContainer {
    display: flex;
    background-color: var(--yellow);
    border-radius: 3px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
}

.inboxButtonContainer:hover {
    background-color: rgb(218, 164, 0);
    cursor: pointer;
}

.inboxButton {
    color: black;
    margin-right: 5px;
    margin-top: 3px;
}