.container {
  flex-grow: 1;
  margin: 0.5rem 0;
}

.label {
  color: white;
  font-size: 1.5em;
}

.input {
  background-color: transparent;
  border: 1px solid var(--dark-teal);
  box-shadow: 0px 1px 0px #000;
}

.input:focus {
  border: 1px solid var(--teal);
  box-shadow: 0 0 2px var(--lightish-teal);
}
