.commentContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.commentImageContainer {
    flex-grow: 0;
}

.commentImageContainer img {
    border: 0.5px solid white;
}

.commentInfoContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.commentInfoContainer .author {
    text-decoration: none;
    font-size: large;
    color: var(--dark-teal);
}

.commentInfoContainer .author:hover {
    color: var(--darker-teal);
}

.commentInfoContainer .comment {
    color: var(--light-teal);
    overflow-wrap: break-word;
}

.commentInfoContainer .date {
    margin: 0;
    font-size: smaller;
    color: grey;
}

.likeContainer .like, .likeContainer .liked {
    width: 1rem;
    height: 1rem;
    margin: 0.125rem;
}

.likeContainer .like {
    cursor: pointer;
}

.likeContainer .like:hover, .likeContainer .liked {
    color: red;
}
