@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to { 
    opacity: 1;
  }
}

@keyframes partial-fade-in {
  from {
    opacity: 0;
  }
  to { 
    opacity: 0.75;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#container {
  padding: 30px;
  display: flex;
  height: 100%;
  width: 100%;
  gap: 30px;
  flex-wrap: wrap;
}

#info {
  width: 400px;
  background-color: #fff;
  border-radius: 10px;
  flex-grow: 1;
  padding: 30px;
  text-align: center;

  opacity: 0;
  animation: fade-in-bottom 1s;
  animation-fill-mode: forwards;
}

#info h1 {
  font-weight: bold;
  cursor: default;
}

#premiumDescription {
  font-size: larger;
  font-weight: bold;
  cursor: default;
}

#purchaseContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;

  opacity: 0;
  animation: fade-in 1s;
  animation-delay: 5.5s;
  animation-fill-mode: forwards;
}

#purchaseContainer .purchaseOption {
  width: 100%;
  height: 64px;
  border: 1px solid rgb(166, 166, 166);
  border-radius: 10px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
  box-shadow: rgba(78, 78, 78, 0.35) 0px 5px 15px;
}

#purchaseContainer .purchaseOption.disabled {
  opacity: 0.5;
  cursor: default;
}

#purchaseContainer .purchaseOption:hover, #purchaseButton:hover {
  background-color: #f2f2f2;
}

#purchaseContainer .purchaseOption.active {
  border: 2px solid var(--teal);
}

#purchaseButton {
  width: 100%;
  height: 48px;
  border: 1px solid rgb(81, 81, 81);
  box-shadow: rgba(78, 78, 78, 0.35) 0px 5px 15px;
  border-radius: 20px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;

  margin-top: 20px;
}

#purchaseButton.disabled {
  background-color: #f2f2f2 !important;
  opacity: 0.5;
  cursor: default;
}

#items {
  flex-grow: 4;
  gap: 40px;
  width: 600px;

  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;

  opacity: 0;
  animation: fade-in-bottom 1s;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}

#items > .item {
  background-color: #e4e4e4;
  border-radius: 10px;
  flex-grow: 1;

  opacity: 0;
  animation: fade-in 1s;
  animation-fill-mode: forwards;

  border: 2px solid rgb(81, 81, 81);
  box-shadow: rgba(78, 78, 78, 0.35) 0px 5px 15px;

  min-height: 100px;
}

#items > .item:nth-child(1) {
  animation-delay: 2.5s;
}

#items > .item:nth-child(2) {
  animation-delay: 3.5s;
}

#items > .item:nth-child(3) {
  animation-delay: 4.5s;
}

#items > .item > .itemContent {
  height: 100%;
  border-radius: 10px;
  padding: 10px;
  background: linear-gradient(to right, rgba(255,255,255, 0.5), var(--teal));

  display: flex;
  justify-content: center;
  flex-direction: column;
}

#items > .item > .itemContent > h2 {
  text-align: right;
  margin-right: 20px;
  color: #fff;
  cursor: default;
}

#items > .item > .itemContent > h4 {
  text-align: right;
  margin-right: 10px;
  color: #fff;
  cursor: default;
}

#successOverlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: black;
  animation: partial-fade-in 1s;
  animation-fill-mode: forwards;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

#successPrompt {
  position: fixed;
  width: 35%;
  min-width: 300px;
  min-height: 50vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 20px;
  z-index: 101;
  padding: 20px;

  opacity: 0;
  animation: fade-in 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

#successPrompt h1 {
  text-align: center;
}

#successPromptImageContainer {
  width: 128px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

#successPromptSubtitle {
  text-align: center;
  margin-top: 20px;
}

#successPromptCompleteButton {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border: 1px solid rgb(81, 81, 81);
  box-shadow: rgba(78, 78, 78, 0.35) 0px 5px 15px;
  border-radius: 10px;
  background-color: var(--dark-teal);
  color: #fff;
  height: 48px;
  font-weight: bolder;
  padding: 10px;
}

#successPromptCompleteButton:hover {
  filter: brightness(90%);
  cursor: pointer;
}