.formGroup {
    padding: 0.5rem 0;
}

.formLabel {
    font-weight: bold;
}

.imgPreviewContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.0625rem solid white;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-bottom: 1rem;
}

.imgPreviewContainer img {
    min-width: 80%;
    min-height: 80%;
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
}