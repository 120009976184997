.loginPageContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    background-color: var(--grey);
    color: white;
    min-height: calc(100vh - 64px);
}

.imageColumn {
    width: 35%;
    background-color: var(--teal);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.imageColorColumn {
    width: 5%;
    background-color: var(--darker-teal);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.loginColumn {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: safe center;
    flex-direction: column;
    padding: 0.5rem;
    overflow-y: auto;
    animation: fadeIn 1s ease-in-out;
}

.imageColumn img {
    max-width: 200%;
    height: auto;
}

.loginTitle {
    font-family: 'Roboto Flex', sans-serif;
    font-size: 80px;
    font-weight: 100;
    text-align: center;
    color: var(--yellow);
    margin-bottom: 20px;
}

.imageColumn img {
    animation: slideDown 1s ease-out forwards;
}

@keyframes slideDown {
    from {
        transform: translateY(-20%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 768px) {
    .imageColumn, .imageColorColumn {
        display: none;
    }
    .loginColumn {
        width: 100%;
    }
    .loginTitle {
        font-size: 70px;
    }
}