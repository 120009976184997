@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.author_card {
    width: 200px;
    height: 260px;
    background-color: rgb(27, 27, 27);
    border-radius: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: fadeIn 2s ease-out forwards;
    animation-delay: inherit;
}

.author_card:hover {
    background-color: rgb(18, 18, 18);
    cursor: pointer;
}

.card img {
    margin: auto;
    display: block;
    min-width: 100px;
    min-height: 100px;
    transition: filter 0.3s ease;
}

.author_card > img {
  height: 7rem;
  width: 7rem;
  border-radius: 50%;
  border: 5px solid var(--dark-teal);
  box-shadow: 0px 2px 0px var(--darker-teal);
  margin-top: 25px;
}


.displayName {
    margin-top: 20px;
    font-size: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.remote {
    background-color: var(--light-teal);
    max-width: 90%;
    margin-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
}

.subDomain {
    /* background-color: var(--yellow); */
    background-color: rgb(0, 131, 145);
    max-width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}