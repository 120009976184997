/* Footer */
.footer {
    padding: 20px 0;
    background-color: var(--dark-grey);
    color: white;
    text-align: center;
    margin-top: auto;
    z-index: 1;
    height: 64px;
}

.footerContainer {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px; /* Adjust as needed */
}
