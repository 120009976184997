.networkPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    margin: auto;
    margin-top: 100px;
}

#NetworkExploreHeader {
    color: white;
    font-size: 25px;
    text-align: center;
    margin-top: 20px;
    background-color: var(--teal);
    border: 2px solid var(--dark-teal);
    width: 40%;
    margin: auto;
    border-radius: 10px;
}

.mainHeader {
    font-size: 36px;
    color: #FFFFFF;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
}

.subHeader {
    font-size: 24px;
    color: #CCCCCC;
    text-align: center;
    margin-bottom: 30px;
}

.authorContainer {
    max-width: 1120px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 200px);
    gap: 30px;
}



.author_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


@media screen and (max-width: 768px) {
    .authorContainer {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}


.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    color: white;
}

.pagination button {
    padding: 8px 12px; /* Increased padding for a larger clickable area */
    cursor: pointer;
    background-color: #424242; /* Darker shade for a sleek look */
    color: #FFFFFF; /* Light text color for contrast */
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition for color and press effect */
}

.pagination button:hover {
    background-color: #444444; /* Slightly lighter shade on hover */
}

.pagination button:active {
    transform: scale(0.95); /* Simulate a button press effect */
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed; /* Ensure it's clear the button is disabled */
    background-color: #3A3A3A; /* Slightly different shade for disabled state */
}

/* .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    color: white;
}

.pagination button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: default;
}
 */
