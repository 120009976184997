#container {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 10px;
}

#avatarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#avatarContainer #avatar {
  position: relative;
  height: 168px;
  width: 168px;
  border: 1.5px solid var(--dark-teal);
  box-shadow: 0px 2px 0px var(--darker-teal);
  padding: 0.5rem;
}


#avatarContainer #avatar:hover {
  filter: brightness(50%);
  cursor: pointer;
}

.saveChangesButton {
  width: 100%;
  margin: 2.5rem 0;
  background-color: var(--dark-teal) !important;
  border-color: var(--dark-teal) !important;
  color: var(--light-teal) !important;
  padding: 0.5rem;
}

.saveChangesButton:hover, .saveChangesButton:focus {
  background-color: var(--darker-teal) !important;
  border-color: var(--darker-teal) !important;
}

#uploadModal {
  color: white;
  text-align: center;
}

#uploadModalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
}

#uploadModalBody * {
  margin-left: auto;
  margin-right: auto;
}

#uploadImageUrlInput {
  width: 50%;
  border-radius: 5px;
  text-align: center;
}